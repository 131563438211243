import React, { createContext } from 'react'
import { TranslatorFunctionType } from '../types/localization'

interface PropsType {
  children: React.ReactNode
  t: TranslatorFunctionType
}

interface ProfileType {
  t: TranslatorFunctionType
}

export const ProfileContext = createContext<ProfileType>(null)

export const ProfileProvider = ({ children, t }: PropsType) => {
  return (
    <ProfileContext.Provider value={{ t }}>{children}</ProfileContext.Provider>
  )
}
