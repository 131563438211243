import React, { createContext } from 'react'
import { AxiosResponse } from 'axios'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { UserTenantMgtSvcClient, Stack } from '@jarvis/web-stratus-client'

interface PropsType {
  stack: Stack
  children: React.ReactNode
  authProvider: JarvisAuthProvider
}

interface StratusType {
  userTenantMgtSvcClient: {
    getCurrentActiveUserWithTenant: () => Promise<AxiosResponse>
    getUpdatedUserInfoIdp: (obj) => Promise<AxiosResponse>
  }
}

export const StratusContext = createContext<StratusType>(undefined)

export const StratusProvider = ({
  children,
  stack,
  authProvider
}: PropsType) => {
  const userClient = new UserTenantMgtSvcClient(stack, authProvider)
  const userTenantMgtSvcClient = {
    getCurrentActiveUserWithTenant: async (): Promise<AxiosResponse> => {
      try {
        const response = await userClient.getCurrentActiveUserWithTenant()
        return response
      } catch (error) {
        return error.response
      }
    },
    getUpdatedUserInfoIdp: async (profileObj): Promise<AxiosResponse> => {
      try {
        return profileObj
      } catch (error) {
        return error.response
      }
    }
  }

  return (
    <StratusContext.Provider value={{ userTenantMgtSvcClient }}>
      {children}
    </StratusContext.Provider>
  )
}
