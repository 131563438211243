import { Stack } from '@jarvis/web-stratus-client'

const getMyAccountUrl = (stack) => {
  if (stack === Stack.prod) {
    return 'https://myaccount.id.hp.com'
  }
  return 'https://myaccount.stg.cd.id.hp.com'
}

export default getMyAccountUrl
