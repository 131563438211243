import React from 'react'
import { PageHeading, Messages, TryAgainButton } from './styles'
import { useTranslator } from '../../../hooks'
import Button from '@veneer/core/dist/scripts/button/button'

const PageError = ({ refreshHandler }) => {
  const t = useTranslator()
  return (
    <>
      <PageHeading className="subtitle-large">
        {t('errorPage.title')}
      </PageHeading>
      <Messages className="body">{t('errorPage.description')}</Messages>
      <TryAgainButton>
        <Button
          onClick={refreshHandler}
          appearance="secondary"
          className="body"
        >
          {t('errorPage.button')}
        </Button>
      </TryAgainButton>
    </>
  )
}
export default PageError
