// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/@veneer/core/dist/css/veneer.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jarvis__react-hpx-profile *{box-sizing:border-box}\n", "",{"version":3,"sources":["webpack://./src/styles/global.scss"],"names":[],"mappings":"AAAgD,6BAA6B,qBAAqB","sourcesContent":["@import url(\"@veneer/core/dist/css/veneer.css\");.jarvis__react-hpx-profile *{box-sizing:border-box}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
