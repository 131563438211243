import React, { ReactElement } from 'react'
import { Text } from './styles'
interface TextWithLinkProps {
  text: string
  replacementObject: {
    initialKey: string
    finalKey: string
    href: string
  }
  typographyClass: string
  endIcon?: ReactElement
  handleClickLink?: () => void
}
export const TextWithLink = ({
  text,
  replacementObject,
  typographyClass,
  endIcon = null,
  handleClickLink = null
}: TextWithLinkProps) => {
  const getSubstrings = (text, tag1, tag2) => {
    const pos1 = text.indexOf(tag1)
    const pos2 = text.indexOf(tag2)
    return [
      text.substring(0, pos1),
      text.substring(pos1 + tag1.length, pos2),
      text.substring(pos2 + tag2.length)
    ]
  }

  const [firstPart, secondPart, thirdPart] = getSubstrings(
    text,
    replacementObject.initialKey,
    replacementObject.finalKey
  )

  return (
    <Text className={typographyClass}>
      {firstPart}
      <a
        target="_blank"
        rel="noreferrer"
        href={replacementObject.href}
        onClick={handleClickLink}
      >
        {secondPart}
        {endIcon}
      </a>
      {thirdPart}
    </Text>
  )
}
