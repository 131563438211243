import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const Text = styled.p`
  > a {
    display: inline-flex;
    align-items: center;
    color: ${tokens.colorHpBlue6};
    text-decoration: none;

    > svg {
      fill: ${tokens.colorHpBlue6};
      margin-left: ${tokens.space2};
    }

    &:hover,
    :focus,
    :active {
      text-decoration: underline;
      color: ${tokens.colorHpBlue7};
    }
  }
`
