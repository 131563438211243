import React from 'react'
import { About, FirstName, LastName, Name, Label } from './aboutYouStyles'
import { useTranslator } from '../../../hooks'

const AboutYouComponent = ({ profileInfo }) => {
  const t = useTranslator()

  return (
    <>
      <About>
        <FirstName>
          <Label className="overline-regular">
            {t('sections.profile.firstName')}
          </Label>
          <Name className="label">{profileInfo.givenName || ''}</Name>
        </FirstName>
        <LastName>
          <Label className="overline-regular">
            {t('sections.profile.lastName')}
          </Label>
          <Name className="label">{profileInfo.familyName || ''}</Name>
        </LastName>
      </About>
    </>
  )
}

export default AboutYouComponent
