import React, { useEffect, useState, useCallback } from 'react'
import { TranslatorFunctionType } from './types/localization'
import { MfePropsType } from './types/mfeProps'
import { ProfileProvider, StratusProvider } from './contexts'
import ProfileComponent from './components/ProfileComponent'
import './styles/global.scss'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import PageError from './components/ProfileComponent/ErrorPage'
import Loader from './components/shared-components/Loader'

const App = ({
  t,
  stack,
  authProvider,
  analytics,
  properties
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const [LDProvider, setLDProvider] = useState(null)
  const [fetchingLD, setFetchingLD] = useState(true)
  const [errorLD, setErrorLD] = useState(false)

  const launchDarklyClientId =
    properties?.launchDarklyClientId || '630cf8eb00abf711e3d0311f'

  const fetchFetureFlags = useCallback(async () => {
    setFetchingLD(true)
    try {
      const Provider = await asyncWithLDProvider({
        clientSideID: launchDarklyClientId
      })
      setLDProvider(() => Provider)
    } catch (err) {
      console.error(err)
      setErrorLD(true)
    } finally {
      setFetchingLD(false)
    }
  }, [launchDarklyClientId])

  useEffect(() => {
    if (!errorLD) {
      fetchFetureFlags()
    }
  }, [errorLD, fetchFetureFlags])

  if (fetchingLD) {
    return <Loader />
  }

  return (
    <LDProvider
      clientSideID={launchDarklyClientId}
      user={{ key: 'test@email.com' }}
    >
      <ProfileProvider t={t}>
        {errorLD ? (
          <PageError refreshHandler={fetchFetureFlags} />
        ) : (
          <StratusProvider stack={stack} authProvider={authProvider}>
            <ProfileComponent analytics={analytics} stack={stack} />
          </StratusProvider>
        )}
      </ProfileProvider>
    </LDProvider>
  )
}

export default App
