import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const Label = styled.h4`
  margin-bottom: ${tokens.space3};

  @media (max-width: ${tokens.lgMax}) {
    margin-bottom: ${tokens.space1};
  }
`

export const ActiveSessionsContainer = styled.div`
  margin-top: ${tokens.space6};

  @media (max-width: ${tokens.lgMax}) {
    margin-top: ${tokens.space5};
  }

  @media (max-width: 375px) {
    margin-top: ${tokens.space4};
  }
`
