import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const LoginInformation = styled.div`
  color: ${tokens.colorGray7};
`

export const Title = styled.h4`
  margin-bottom: ${tokens.space3};
`

export const VerifiedText = styled.p`
  padding-left: 5px;
`

const ContainerWithBorderBottom = styled.div`
  border-bottom: 1px solid ${tokens.colorGray3};
`

export const ContainerVerified = styled(ContainerWithBorderBottom)`
  display: flex;
  align-items: flex-end;
  margin-top: ${tokens.space2};
  padding-bottom: 13px;

  > svg {
    fill: ${({ verified }) =>
      verified ? tokens.colorGreen6 : tokens.colorGray5};
  }
`

export const NumberVerified = styled(ContainerVerified)``

export const VerifiedInfo = styled(ContainerVerified)``

export const TwoFactorContainer = styled(ContainerWithBorderBottom)`
  padding: ${tokens.space5} 0 ${tokens.space4} 0;

  @media (max-width: ${tokens.lgMax}) {
    padding: ${tokens.space5} 0 ${tokens.space2} 0;
  }

  @media (max-width: ${tokens.xsMax}) {
    padding: ${tokens.space6} 0 ${tokens.space2} 0;
  }
`

export const TwoFactorLabel = styled.h5``

export const VerifyMobileText = styled.p`
  padding-left: 5px;
`

export const MobileNumber = styled.p`
  word-break: break-word;

  @media (max-width: ${tokens.xsMax}) {
    margin-top: ${tokens.space4};
  }
`

export const TwoFactorLink = styled.div`
  padding: ${tokens.space3} 0;
`

export const MobileNumberLabel = styled.h5``

export const EmailInfo = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  margin-top: 22px;

  @media (max-width: ${tokens.xsMax}) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: ${tokens.space4};
  }
`
export const EmailLabel = styled.h5``

export const EmailName = styled.p`
  word-break: break-word;

  @media (max-width: ${tokens.xsMax}) {
    margin-top: ${tokens.space2};
  }
`

export const SignInGrid = styled.div`
  margin-top: ${tokens.space7};

  @media (max-width: ${tokens.lgMax}) {
    margin-top: ${tokens.space6};
  }

  @media (max-width: ${tokens.xsMax}) {
    margin-top: ${tokens.space8};
  }
`

export const NumberInfo = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  margin-top: 22px;

  @media (max-width: ${tokens.xsMax}) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: ${tokens.space6};
  }
`
