import styled from 'styled-components'

import tokens from '@veneer/tokens'

export const About = styled.div``

export const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  padding: 22px 0;
  border-bottom: 1px solid ${tokens.colorGray3};

  @media (max-width: ${tokens.xsMax}) {
    border-bottom: 1px solid ${tokens.colorGray4};
    padding: ${tokens.space5} 0 14px 0;
    grid-template-columns: repeat(1, 1fr);
    gap: ${tokens.space5};
  }

  @media (max-width: 375px) {
    padding-top: ${tokens.space6};
  }
`

export const FirstName = styled(AboutContainer)`
  margin-top: ${tokens.space1};

  @media (max-width: 375px) {
    margin-top: 0;
    padding-top: ${tokens.space8};
  }
`

export const LastName = styled(AboutContainer)``

export const MainLabel = styled.div`
  color: #000000;
  display: flex;
  flex-direction: row;
  gap: 29px;
  > div > svg {
    fill: ${tokens.colorHpBlue6};
  }
`

export const Name = styled.p`
  color: ${tokens.colorGray7};
  word-break: break-word;
`

export const Label = styled.p`
  color: ${tokens.colorGray5};
`
