import React from 'react'
import {
  VerifiedInfo,
  MobileNumber,
  LoginInformation,
  Title,
  VerifiedText,
  NumberVerified,
  TwoFactorContainer,
  EmailInfo,
  EmailLabel,
  MobileNumberLabel,
  TwoFactorLabel,
  VerifyMobileText,
  EmailName,
  TwoFactorLink,
  SignInGrid,
  NumberInfo
} from './loginInformationStyles'
import {
  IconExternalLink,
  IconCheckCircle,
  IconHelp
} from '../../../assets/icons'
import { TextWithLink } from '../../shared-components/TextWithLink'
import { useTranslator } from '../../../hooks'
import { publishHyperLinkClickedEvent } from '../../../utils/analytics'
import getMyAccountUrl from '../../../utils/urlResolver'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LoadingIndicator } from '../styles'

function GetTwoFactorLink({ analytics, stack }) {
  const flags = useFlags()
  const sendTrackingId = flags['hpxMyAccountProfileTrackingId']
  const t = useTranslator()

  const manageTwoFactorHref = `${getMyAccountUrl(stack)}/security`
  const sendTwoFactorLinkEvent = () => {
    publishHyperLinkClickedEvent(
      analytics,
      'ManageTwoFactorAuthentication',
      manageTwoFactorHref,
      sendTrackingId
    )
  }

  return (
    <TextWithLink
      typographyClass="caption"
      text={t('sections.signInInformation.descriptions.description4')}
      replacementObject={{
        initialKey: '{{tagReplaceStart}}',
        finalKey: '{{tagReplaceEnd}}',
        href: manageTwoFactorHref
      }}
      endIcon={<IconExternalLink size={20} />}
      handleClickLink={sendTwoFactorLinkEvent}
    />
  )
}

const LoginInformationComponent = ({
  profileInfo,
  analytics,
  stack,
  isLoading
}) => {
  const t = useTranslator()
  const flags = useFlags()
  const hideHpLinks = flags['hpxHideHpIdLinksInProfile']
  const sendTrackingId = flags['hpxMyAccountProfileTrackingId']

  const emailVerified = !!(profileInfo.email && profileInfo.email.verified)

  const phoneNumber = profileInfo.phoneNumber
    ? profileInfo.phoneNumber.value
    : ''
  const phoneNumberVerified =
    profileInfo.phoneNumber && profileInfo.phoneNumber.verified

  const updateSecuritySettingsHref = `${getMyAccountUrl(stack)}/security`
  const sendUpdateSecuritySettingsLinkEvent = () => {
    publishHyperLinkClickedEvent(
      analytics,
      'UpdateSecuritySettings',
      updateSecuritySettingsHref,
      sendTrackingId
    )
  }

  const learnMoreHref = `${getMyAccountUrl(
    stack
  )}/webcomponents/static/mfa.html`
  const sendLearnMoreLinkEvent = () => {
    publishHyperLinkClickedEvent(
      analytics,
      'TwoFactorAuthenticationLearnMore',
      learnMoreHref,
      sendTrackingId
    )
  }

  const getVerificationMessage = (verified) => {
    return verified
      ? t('sections.signInInformation.descriptions.description2')
      : t('sections.signInInformation.descriptions.description3')
  }

  return (
    <LoginInformation>
      <SignInGrid>
        <Title className="title-small">
          {t('sections.signInInformation.headers.header1')}
        </Title>
      </SignInGrid>
      {isLoading ? (
        <LoadingIndicator appearance="linear" behavior="indeterminate" />
      ) : (
        <>
          <SignInGrid>
            {!hideHpLinks && (
              <TextWithLink
                typographyClass="caption"
                text={t('sections.signInInformation.descriptions.description1')}
                replacementObject={{
                  initialKey: '{{tagReplaceStart}}',
                  finalKey: '{{tagReplaceEnd}}',
                  href: `${getMyAccountUrl(stack)}/security`
                }}
                endIcon={<IconExternalLink size={20} />}
                handleClickLink={sendUpdateSecuritySettingsLinkEvent}
              />
            )}
          </SignInGrid>
          <EmailInfo>
            <EmailLabel className="label">
              {t('sections.signInInformation.headers.header2')}
            </EmailLabel>
            {profileInfo.email && profileInfo.email.value && (
              <EmailName className="label">{profileInfo.email.value}</EmailName>
            )}
          </EmailInfo>
          <VerifiedInfo verified={emailVerified}>
            <IconCheckCircle filled size={20} />
            <VerifiedText className="caption-small">
              {getVerificationMessage(emailVerified)}
            </VerifiedText>
          </VerifiedInfo>
          <NumberInfo>
            <MobileNumberLabel className="label">
              {t('sections.signInInformation.headers.header3')}
            </MobileNumberLabel>
            {phoneNumber && (
              <MobileNumber className="label">{phoneNumber}</MobileNumber>
            )}
          </NumberInfo>
          <NumberVerified verified={phoneNumberVerified}>
            <IconHelp filled size={20} />
            <VerifyMobileText className="caption-small">
              {getVerificationMessage(phoneNumberVerified)}
            </VerifyMobileText>
          </NumberVerified>
          <TwoFactorContainer>
            <TwoFactorLabel className="label">
              {t('sections.signInInformation.headers.header4')}
            </TwoFactorLabel>
            {!hideHpLinks && (
              <TwoFactorLink>
                <GetTwoFactorLink analytics={analytics} stack={stack} />
              </TwoFactorLink>
            )}
            {!hideHpLinks && (
              <TextWithLink
                typographyClass="caption"
                text={t('sections.signInInformation.descriptions.description5')}
                replacementObject={{
                  initialKey: '{{tagReplaceStart}}',
                  finalKey: '{{tagReplaceEnd}}',
                  href: learnMoreHref
                }}
                handleClickLink={sendLearnMoreLinkEvent}
              />
            )}
          </TwoFactorContainer>
        </>
      )}
    </LoginInformation>
  )
}
export default LoginInformationComponent
