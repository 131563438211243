import styled from 'styled-components'
import tokens from '@veneer/tokens'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'

export const Container = styled.div`
  background-color: transparent;
  color: ${tokens.colorGray7};
  padding: 40px;
  @media (max-width: 375px) {
    padding: ${tokens.space6} ${tokens.space4};
  }
`

export const TextContainer = styled.div`
  margin: ${tokens.space2} 0 ${tokens.space3} 0;
`

export const ProfileHeader = styled.h4``

export const Descriptions = styled.div``

export const LoadingIndicator = styled(ProgressIndicator)`
  margin-top: 20px;
`
