const ACTIVITY = 'AcctMgmt-v01'
const EVENT_DETAIL_VERSION = '1.3.0'
const SCREEN_NAME = 'Profile'
const SCREEN_PATH = '/ReactHpxProfile/'

export const publishScreenDisplayedEvent = (analytics, sendTrackingId) => {
  const options = {
    ...(sendTrackingId && {
      metadata: {
        trackingIdentifier: SCREEN_NAME + '-' + Date.now()
      }
    })
  }

  analytics.publishCdmEvents(
    [
      {
        dateTime: new Date().toISOString(),
        eventDetailType:
          'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
        eventCategory: 'simpleUi',
        eventDetail: {
          activity: ACTIVITY,
          version: EVENT_DETAIL_VERSION,
          screenPath: SCREEN_PATH,
          screenName: SCREEN_NAME,
          action: 'ScreenDisplayed'
        },
        version: EVENT_DETAIL_VERSION
      }
    ],
    options
  )
}

export const publishHyperLinkClickedEvent = (
  analytics,
  controlName,
  href,
  sendTrackingId
) => {
  const options = {
    ...(sendTrackingId && {
      metadata: {
        trackingIdentifier: SCREEN_NAME + '-' + Date.now()
      }
    })
  }

  analytics.publishCdmEvents(
    [
      {
        dateTime: new Date().toISOString(),
        eventDetailType:
          'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
        eventCategory: 'simpleUi',
        eventDetail: {
          activity: ACTIVITY,
          version: EVENT_DETAIL_VERSION,
          screenPath: SCREEN_PATH,
          screenName: SCREEN_NAME,
          action: 'ControlHyperLinkClicked',
          controlName: controlName,
          controlDetail: href
        },
        version: EVENT_DETAIL_VERSION
      }
    ],
    options
  )
}
